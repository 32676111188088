<template>
  <div>
    <b-row>
      <b-col cols="12">
        <Grid
          class="grid-full-height"
          :data-items="result"
          :columns="columns"
          :column-menu="true"
          :take="dataState.take"
          :skip="dataState.skip"
          :sort="dataState.sort"
          :filter="dataState.filter"
          :group="dataState.group"
          :expand-field="'expanded'"
          :sortable="true"
          :reorderable="true"
          :resizable="true"
          :groupable="false"
          :pageable="true"
          :filterable="true"
          :page-size="50"
          @datastatechange="onDataStateChange"
          @filterchange="onFilterChange"
          @sortchange="onSortChange"
          @columnreorder="onColumnReorder"
        >
          <!-- Custom toolbar -->
          <GridToolbar>

            <toolbar-item-view-manager
              :grid-id="gridId"
              :columns="columns"
              :data-state="dataState"
              :original-columns="originalColumns"
              :original-data-state="originalDataState"
              :current-view-name="currentViewName"
              :current-view-id="currentViewId"
              @resetToDefaultViewClick="resetToDefaultView"
              @applyView="onApplyView"
            />

            <toolbar-item-column-manager
              :columns="activeColumns"
              :original-columns="originalColumns"
              @columnssubmit="columns = $event"
            />

            <toolbar-item-export-excel
              :data-items="dataItems"
              :columns="activeColumns"
              :data-state="dataState"
            />

            <toolbar-item-loading-indicator
              :is-loading="isLoading"
            />

          </GridToolbar>

          <!-- Custom action cell -->
          <template v-slot:cellActionTemplate="{ props }">
            <DetailLinkActionCellTemplate
              :field="props.field"
              :row-type="props.rowType"
              :class-name="props.className"
              to="host:manage:tenant-user-detail"
              :to-params="{ userid: props.dataItem.id, id: props.dataItem.tenantId }"
            />
          </template>

          <!-- Profile image cell -->
          <template v-slot:cellProfileImage="{ props }">
            <UserProfileImageCellTemplate
              :data-item="props.dataItem"
              :field="props.field"
              :row-type="props.rowType"
              :class-name="props.className"
            />
          </template>
        </Grid>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'
import { process } from '@progress/kendo-data-query'
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'

import {
  DetailLinkActionCellTemplate, UserProfileImageCellTemplate, ToolbarItemViewManager, ToolbarItemColumnManager, ToolbarItemExportExcel, ToolbarItemLoadingIndicator,
} from '@/components/grid'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'

import UserService from '@/service/user.service'

export default {
  components: {
    BRow,
    BCol,
    Grid,
    GridToolbar,
    DetailLinkActionCellTemplate,
    UserProfileImageCellTemplate,
    ToolbarItemExportExcel,
    ToolbarItemColumnManager,
    ToolbarItemViewManager,
    ToolbarItemLoadingIndicator,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  data() {
    return {
      gridId: 'user',
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          title: ' ',
          locked: true,
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          static: true,
          columnMenu: false,
        },
        {
          cell: 'cellProfileImage',
          title: ' ',
          orderIndex: 1,
          width: '70px',
          filterable: false,
          sortable: false,
          groupable: false,
          columnMenu: false,
        },
        {
          field: 'name',
          title: 'Firstname',
          orderIndex: 2,
          width: '200px',
        },
        {
          field: 'surname',
          title: 'Lastname',
          orderIndex: 3,
          width: '200px',
        },
        {
          field: 'email',
          title: 'Email',
          orderIndex: 4,
          width: '400px',
        },
        {
          field: 'isActive',
          title: 'Active',
          orderIndex: 5,
          width: '100px',
          filter: 'boolean',
          type: 'boolean',
          columnMenu: false,
        },
        {
          field: 'twoFactorEnabled',
          title: '2FA',
          orderIndex: 6,
          width: '100px',
          filter: 'boolean',
          type: 'boolean',
          columnMenu: false,
        },
      ],
    }
  },
  computed: {
    result() {
      return process(this.dataItems, this.dataState)
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.isLoading = true
      this.dataItems = await UserService.getAllListAsync({ disableTenantFilter: true })
      this.isLoading = false
    },
  },
}
</script>
